import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { EChartsOption } from "echarts";

import { BaseComponent } from "../../../base/components/base/base-component";
import { Chart } from "../../datamodel/chart";
import { ChartPoint } from "../../datamodel/chart-point";

/**
 * Component to show a chart.
 */
@Component({
    selector: "app-graph",
    templateUrl: "./graph.component.html",
    styleUrls: ["./graph.component.scss"]
})
/* eslint-disable @typescript-eslint/no-magic-numbers */
export class GraphComponent extends BaseComponent {
    private readonly thinSpace: string = " ";

    @Input()
    private charts: Array<Chart> = [];

    constructor(
        private modalController: ModalController
    ) {
        super();
    }

    protected override componentInit(): void {
        this.populateChartOptions();
    }
    protected override componentDestroy(): void {
        // Nothing
    }

    private populateChartOptions(): void {
        if (this.charts.length <= 0) {
            return;
        }

        // Collect and sort all x-axis values
        const allXValues: Set<number> = new Set<number>();
        const allXUnits: Set<string|undefined> = new Set<string|undefined>();
        const allYUnits: Set<string|undefined> = new Set<string|undefined>();
        let showXAxis: boolean = true;
        let showYAxis: boolean = true;
        this.charts.forEach((chart: Chart) => {
            allXUnits.add(chart.unitX);
            allYUnits.add(chart.unitY);
            showXAxis = showXAxis && chart.showXAxis;
            showYAxis = showYAxis && chart.showYAxis;
            chart.data.forEach((point: ChartPoint) => {
                allXValues.add(point.x);
            });
        });
        const sortedXValues: Array<number> = Array.from(allXValues).sort((a: number, b: number) => a - b);

        const unitX: string = allXUnits.size == 1 && allXUnits.values().next().value !== undefined ? `${this.thinSpace}${allXUnits.values().next().value}` : "";
        const unitY: string = allYUnits.size == 1 && allYUnits.values().next().value !== undefined ? `${this.thinSpace}${allYUnits.values().next().value}` : "";
        this.chartOption.xAxis = {
            type: "category",
            data: sortedXValues,
            axisLabel: {
                formatter: (value: string) => showXAxis ? `${Number(value).toFixed(2)}${(unitX)}` : ""
            }
        };

        let minX: number = Number.MAX_VALUE;
        let maxX: number = Number.MIN_VALUE;
        let minY: number = Number.MAX_VALUE;
        let maxY: number = Number.MIN_VALUE;

        // Populate series data
        this.chartOption.series = this.charts.map((chart: Chart) => {
            let localMinX: number = Number.MAX_VALUE;
            let localMaxX: number = Number.MIN_VALUE;
            let localMinY: number = Number.MAX_VALUE;
            let localMaxY: number = Number.MIN_VALUE;

            const data: Array<[number, number]> = chart.data.map((point: ChartPoint) => {
                localMinX = Math.min(localMinX, point.x);
                localMaxX = Math.max(localMaxX, point.x);
                localMinY = Math.min(localMinY, point.y);
                localMaxY = Math.max(localMaxY, point.y);
                return [point.x, point.y];
            });

            // Calculate the center X position and find corresponding index in sortedXValues
            // const centerX: number = (localMinX + localMaxX) / 2.0;
            // const centerXIndex: number = sortedXValues.findIndex((value: number) => value >= centerX);
            // const centerXValue: number = sortedXValues[centerXIndex];

            // Update global min and max values
            minX = Math.min(minX, localMinX);
            maxX = Math.max(maxX, localMaxX);
            minY = Math.min(minY, localMinY);
            maxY = Math.max(maxY, localMaxY);

            const markLines: Array<any> = [];
            if (chart.showMin) { markLines.push({ type: "min", name: "Min", label: { show: true, formatter: `Min: {c}${unitY}` } }); }
            if (chart.showMax) { markLines.push({ type: "max", name: "Max", label: { show: true, formatter: `Max: {c}${unitY}` } }); }
            if (chart.showAverage) { markLines.push({ type: "average", name: "Average", label: { show: true, formatter: `Avg: {c}${unitY}` } }); }

            return ({
                name: chart.title,
                type: "line",
                smooth: true,
                symbol: "none",
                lineStyle: {
                    width: 3,
                    shadowColor: "rgba(0,0,0,0.3)",
                    shadowBlur: 10,
                    shadowOffsetY: 8
                },
                data: data.map((d: [number, number]) => d[1]),
                markLine: {
                    silent: true,
                    symbol: ["none", "none"],
                    data: markLines
                }
            });
        });

        // Add buffer to Y-axis
        const buffer: number = (maxY - minY) * 0.1;
        this.chartOption.yAxis = {
            type: "value",
            min: minY - buffer,
            max: maxY + buffer,
            axisLabel: {
                formatter: (value: number) => showYAxis ? `${value.toFixed(2)}${unitY}` : ""
            }
        };

        // Add visual map configuration if needed
        this.chartOption.visualMap = [
            {
                show: false,
                type: "continuous",
                seriesIndex: 0,
                min: minY,
                max: maxY,
                color: ["#6300ae", "#00008b"]
            }
        ];
    }

    public chartOption: EChartsOption = {
        visualMap: [],
        animationDuration: 250,
        xAxis: {
            type: "category",
            data: []
        },
        yAxis: {
            type: "value"
        },
        tooltip: {
            trigger: "none"
        },
        series: [
        ]
    };

    protected close(): void {
        this.modalController.dismiss().then();
    }
}
