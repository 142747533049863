import { BleClient, BleDevice } from "@capacitor-community/bluetooth-le";
import { BleService } from "@capacitor-community/bluetooth-le/dist/esm/definitions";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";

import { Chart } from "../../../../business/datamodel/chart";
import { ChartPoint } from "../../../../business/datamodel/chart-point";
import { MeasuredValue } from "../../../../business/datamodel/measured-value";
import { Measurement } from "../../../../business/datamodel/measurement";
import { BackendService } from "../../../../business/services/backend/backend-service";
import { DialogService } from "../../../../business/services/dialog/dialog.service";
import { SettingsService } from "../../../../business/services/settings/settings-service";
import { DeviceSubscriptionStatus } from "../../../../business/services/subscription/device.subscription.status";
import { BluetoothStatus } from "../bluetooth-status";
import { BluetoothDevice } from "./bluetooth.device";
import { DeviceCodes } from "./device-codes";
import { DeviceNames } from "./device-names";

/**
 * This class implements creating a connection the device "Detail-O-Meter IQ" via bluetooth low energy and handles reading measurements from it.
 */
export class Glossmeter extends BluetoothDevice {
    public constructor(
        backendService: BackendService,
        settingsService: SettingsService,
        private readonly dialogService: DialogService,
        private readonly translateService: TranslateService
    ) {
        super(DeviceNames.unknown, backendService, settingsService);
    }

    public hiddenValues: Array<string> = [];

    private readonly measurementService: string = "49535343-fe7d-4ae5-8fa9-9fafd205e455";
    private measurementCharacteristic: string = "49535343-1E4D-4BD9-BA61-23C647249616";

    private readonly deviceInfoService: string = "ef9b2fb9-dc06-4432-8f46-f292abec4e93";
    private deviceInfoCharacteristic: string = "64767bab-ea07-4f78-9796-3b27cf565029";

    public override requiredNamePrefix?: string = undefined;
    public requiredServices: Array<string> = [this.measurementService];
    public requiredAdvertisingServices: Array<string> = [this.deviceInfoService];
    private device?: BleDevice;

    private skipMeasurement: boolean = false;
    private lastDataTimestamp: number|undefined = undefined;
    private mergeWithPreviousLine: boolean = false;
    private lineBuffer: Array<string> = [];

    public async connect(device: BleDevice, onData: (measurement: Measurement) => void, onDisconnect: () => void): Promise<BluetoothStatus> {
        this.device = device;

        await BleClient.connect(this.device.deviceId, () => {
            this.device = undefined;
            onDisconnect();
        });

        this.serialNumber = await this.readSerialNumber();

        const subscriptionStatus: DeviceSubscriptionStatus = await this.getSubscriptionStatus();
        if (subscriptionStatus != DeviceSubscriptionStatus.active) {
            onDisconnect();
            await this.disconnect();
            this.dialogService.licenseRequiredDialog(subscriptionStatus, this.serialNumber);
            return BluetoothStatus.disconnected;
        }

        this.updateDeviceCodeAndName();
        this.updateHiddenValues();

        await BleClient.startNotifications(this.device.deviceId, this.measurementService, this.measurementCharacteristic, (dataView: DataView) => { this.processMeasurementData(dataView, onData); });
        return BluetoothStatus.connected;
    }

    // eslint-disable-next-line complexity
    private processMeasurementData(dataView: DataView, onData: (measurement: Measurement) => void): void {
        const decoder: TextDecoder = new TextDecoder();
        const data: string = decoder.decode(dataView).replace(/\r/g, "");
        const endsWithSeparator: boolean = data.length > 0 && data[data.length - 1] == "\n";
        const startsWithSeparator: boolean = data.length > 0 && data[0][0] == "\n";
        let lines: Array<string> = data.split("\n").map((value?: string): string => value?.trim() ?? "").filter((value: string) => !!value);
        if (lines.length <= 0) {
            return;
        }

        // Reset if some time has passed without data
        const currentTimestamp: number = new Date().getTime();
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (this.lastDataTimestamp && (currentTimestamp - this.lastDataTimestamp) > 2000) {
            this.skipMeasurement = false;
            this.lineBuffer = [];
        }
        this.lastDataTimestamp = currentTimestamp;

        // Detect SOF
        if (!this.lineBuffer.length && lines[0] != "SOF" && !this.skipMeasurement) {
            this.skipMeasurement = true;
            console.warn("SOF/EOF option not activated.");
            this.dialogService.toast("warn", this.translateService.instant("ErrorDialog.SofEofOptionNotActivatedText"), this.translateService.instant("ErrorDialog.SofEofOptionNotActivatedTitle"), true);
            return;
        }
        this.skipMeasurement = false;

        for (let lineIndex: number = 0; lineIndex < lines.length; lineIndex++) {
            const rawLine: string = lines[lineIndex];
            const line: string = rawLine.trim();
            if (line) {
                if (lineIndex == 0 && this.mergeWithPreviousLine && this.lineBuffer.length > 0 && !startsWithSeparator) {
                    this.lineBuffer[this.lineBuffer.length - 1] = this.lineBuffer[this.lineBuffer.length - 1] + line;
                } else {
                    this.lineBuffer.push(line);
                }
            }
        }
        this.mergeWithPreviousLine = !endsWithSeparator;

        if (this.lineBuffer.length <= 0) {
            return;
        }

        // Detect EOF
        if (this.lineBuffer[this.lineBuffer.length - 1] != "EOF") {
            return;
        }

        lines = this.lineBuffer.filter((value: string) => value != "SOF" && value != "EOF");
        console.info(lines);
        this.lineBuffer = [];

        // Detect if ID option has been activated
        if (!lines[0].includes("=")) {
            console.warn("ID option not activated.");
            this.dialogService.toast("warn", this.translateService.instant("ErrorDialog.IdOptionNotActivatedText"), this.translateService.instant("ErrorDialog.IdOptionNotActivatedTitle"), true);
            return;
        }

        const chartsAllowed: boolean = this.deviceCode != DeviceCodes.detailometer;

        const measurement: Measurement = new Measurement();
        measurement.device = this.deviceName;
        measurement.deviceId = this.device?.deviceId;
        measurement.timestamp = DateTime.now().toISO() ?? undefined;
        measurement.values = [];
        measurement.charts = [];
        let atLeastOneValidValue: boolean = false;
        for (const line of lines) {
            if (line.includes("=")) {
                const splits: Array<string> = line.split("=");
                if (splits.length == 2 && splits[0].trim().length > 0 && splits[1].trim().length > 0) {
                    const valueName: string = splits[0];
                    const value: string = splits[1].trim();
                    if (valueName == "Serial No.") {
                        measurement.serialNumber = value;
                    } else if (valueName.startsWith("D-") || valueName.startsWith("D+")) {
                        if (!chartsAllowed) {
                            continue;
                        }
                        if (measurement.charts.length <= 0) {
                            const chart: Chart = new Chart();
                            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                            chart.centerX = 20.0;
                            chart.unitX = "°";
                            chart.unitY = "GU";
                            chart.showXAxis = true;
                            chart.showYAxis = false;
                            chart.showMin = false;
                            chart.showMax = false;
                            chart.showAverage = false;
                            measurement.charts.push(chart);
                        }

                        const xValue: number = Number.parseFloat(valueName.substring(1));
                        const yValue: number = Number.parseFloat(value);
                        if (!Number.isNaN(xValue) && !Number.isNaN(yValue)) {
                            const chart: Chart = measurement.charts[0];
                            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                            chart.data.push(new ChartPoint(20.0 + xValue, yValue));
                        }
                    } else if (!this.hiddenValues.includes(valueName)) {
                        atLeastOneValidValue = true;
                        measurement.values.push({
                            name: `Measurement.${valueName}`,
                            value: value,
                            unit: this.getUnit(valueName)
                        });
                    }
                }
            }
        }
        if (atLeastOneValidValue) {
            this.postProcessMeasurement(measurement);
            onData(measurement);
        }
    }

    private postProcessMeasurement(measurement: Measurement): void {
        const gloss85: MeasuredValue|undefined = measurement.values.find((value: MeasuredValue) => value.name?.includes("GLOSS85"));
        if (gloss85?.value && Number(gloss85.value) > 0) {
            // Remove 85° if it is <= 0 and there are values for 20° and/or 60°
            const gloss20: number = Number(measurement.values.find((value: MeasuredValue) => value.name?.includes("GLOSS20"))?.value ?? 0);
            const gloss60: number = Number(measurement.values.find((value: MeasuredValue) => value.name?.includes("GLOSS60"))?.value ?? 0);
            if (gloss20 + gloss60 > 0) {
                measurement.values = measurement.values.filter((value: MeasuredValue) => value !== gloss85);
            }
        }
    }

    public async disconnect(): Promise<void> {
        if (this.device) {
            await BleClient.disconnect(this.device.deviceId);
        }
        this.device = undefined;
    }

    public async isConnected(): Promise<boolean> {
        try {
            if (this.device) {
                const services: Array<BleService> = await BleClient.getServices(this.device.deviceId);
                return services.length > 0;
            }
        } catch (error: any) {
            console.error(error);
        }
        return false;
    }

    private async readSerialNumber(): Promise<string> {
        if (this.device) {
            const deviceInfo: DataView = await BleClient.read(this.device.deviceId, this.deviceInfoService, this.deviceInfoCharacteristic);
            const deviceInfoAsString: string = new TextDecoder().decode(deviceInfo);

            const splits: Array<string> = deviceInfoAsString.split("-");
            if (splits.length > 2) {
                return splits[1];
            }
        }
        return "unknown device";
    }

    private updateDeviceCodeAndName(): void {
        if (!this.device?.name) {
            this.deviceName = DeviceNames.iq;
            return;
        }
        const parts: Array<string> = this.device.name.split("-");
        if (parts.length < 2) {
            this.deviceCode = DeviceCodes.iq;
            this.deviceName = DeviceNames.iq;
            return;
        }

        switch (parts[0]) {
            case DeviceCodes.detailometer:
                this.deviceCode = DeviceCodes.detailometer;
                this.deviceName = DeviceNames.detailometer;
                break;
            case DeviceCodes.duo:
                this.deviceCode = DeviceCodes.duo;
                this.deviceName = DeviceNames.duo;
                break;
            case DeviceCodes.novoGloss:
                this.deviceCode = DeviceCodes.novoGloss;
                this.deviceName = DeviceNames.novoGloss;
                break;
            case DeviceCodes.flex:
                this.deviceCode = DeviceCodes.flex;
                this.deviceName = DeviceNames.flex;
                break;
            default:
                this.deviceCode = DeviceCodes.iq;
                this.deviceName = DeviceNames.iq;
                break;
        }
    }

    private updateHiddenValues(): void {
        this.hiddenValues = [
            "Time",
            "Date",
            "Pass/Fail",
            "Calibrated",
            "Serial No.",
            "Certified",
            "Cdiode",
            "ASTM D4039"
        ];

        if (this.deviceCode == DeviceCodes.detailometer) {
            this.hiddenValues.push(...["Haze C", "HAZE C", "GLOSS60", "GLOSS85"]);
        }
    }

    private getUnit(valueName: string): string|undefined {
        switch (valueName.toUpperCase()) {
            case "GLOSS20": return "GU";
            case "GLOSS60": return "GU";
            case "GLOSS85": return "GU";
            case "HAZE": return "HU";
            case "HAZE C": return "HU";
            case "LOGHAZE": return "log(HU)";
            case "LOGHAZE C": return "log(HU)";
            case "DOI": return "%";
            case "RIQ": return "%";
            case "RSPEC": return "GU";
            default: return undefined;
        }
    }
}
