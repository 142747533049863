import { ChartPoint } from "./chart-point";

/**
 * Charting data.
 */
export class Chart {
    public title?: string;
    public data: Array<ChartPoint> = [];
    public centerX?: number;
    public unitX?: string;
    public unitY?: string;

    public showXAxis: boolean = true;
    public showYAxis: boolean = true;

    public showMin: boolean = true;
    public showMax: boolean = true;
    public showAverage: boolean = true;
}
