import { BleDevice } from "@capacitor-community/bluetooth-le";

import { Measurement } from "../../../../business/datamodel/measurement";
import { BackendService } from "../../../../business/services/backend/backend-service";
import { SettingsService } from "../../../../business/services/settings/settings-service";
import { DeviceSubscriptionStatus } from "../../../../business/services/subscription/device.subscription.status";
import { BluetoothStatus } from "../bluetooth-status";
import { DeviceCodes } from "./device-codes";
import { DeviceNames } from "./device-names";

/**
 * Baseclass for all Bluetooth devices which are supported in this application.
 */
export abstract class BluetoothDevice {
    protected constructor(
        name: string,
        protected readonly backendService: BackendService,
        protected readonly settingsService: SettingsService
    ) {
        this.deviceName = name;
    }

    public deviceCode: string = DeviceCodes.unknown;
    public deviceName: string = DeviceNames.unknown;

    public serialNumber?: string;

    public abstract requiredServices: Array<string>;
    public abstract requiredAdvertisingServices: Array<string>;
    public abstract requiredNamePrefix?: string;

    public abstract connect(device: BleDevice, onData: (measurement: Measurement) => void, onDisconnect: () => void): Promise<BluetoothStatus>;

    public abstract disconnect(): Promise<void>;

    public abstract isConnected(): Promise<boolean>;

    protected async getSubscriptionStatus(): Promise<DeviceSubscriptionStatus> {
        if (!this.settingsService.licenseCheckEnabled) {
            return DeviceSubscriptionStatus.active;
        }
        return await this.backendService.hasValidLicense(this.serialNumber);
    }
}
