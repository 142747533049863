import { Injectable } from "@angular/core";
import { DateTime } from "luxon";

import { SubscriptionDto } from "../../../generated/api";
import { DeviceSubscriptionStatus } from "../subscription/device.subscription.status";
import { SubscriptionService } from "../subscription/subscription.service";

/**
 * Service to communicate with the backend.
 */
@Injectable({
    providedIn: "root"
})
export class BackendService {
    constructor(
        private readonly subscriptionService: SubscriptionService
    ) {
    }

    public async hasValidLicense(serialNumber?: string): Promise<DeviceSubscriptionStatus> {
        if (!serialNumber) {
            return DeviceSubscriptionStatus.noSubscription;
        }

        let status: DeviceSubscriptionStatus = DeviceSubscriptionStatus.noSubscription;
        try {
            const subscription: SubscriptionDto|undefined = await this.subscriptionService.getFromServer(serialNumber);
            if (subscription) {
                status = subscription.active ? DeviceSubscriptionStatus.active : DeviceSubscriptionStatus.expired;
            }
        } catch (e) {
            status = DeviceSubscriptionStatus.failedToCheck;
            const subscriptions: Array<SubscriptionDto> = await this.subscriptionService.findByDeviceSerial(serialNumber);
            if (subscriptions.length > 0) {
                status = DeviceSubscriptionStatus.expired;
                for (const sub of subscriptions) {
                    if (sub.active && sub.currentTermEnds && this.isCurrentTermEndAfterNow(sub.currentTermEnds)) {
                        status = DeviceSubscriptionStatus.active;
                        break;
                    }
                }
            }
        }
        return status;
    }

    private isCurrentTermEndAfterNow(currentTermEnd: string): boolean {
        const end: DateTime = DateTime.fromFormat(currentTermEnd, "yyyy-MM-dd");
        return end >= DateTime.now();
    }
}
