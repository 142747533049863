import { AbstractEntity } from "../../datamodel/abstract-entity";

/**
 * Entity to store personalization data.
 */
export class Personalization extends AbstractEntity {
    public companyName?: string;
    public companyAddress?: string;
    public website?: string;
    public phone?: string;
    public email?: string;
    public logoAsDataUrl?: string;

    public lengthUnit?: string = "µm";
}
