import { CryptoHelper } from "../../../../../base/helper/crypto-helper";
import { Chart } from "../../../../datamodel/chart";
import { Measurement } from "../../../../datamodel/measurement";
import { ChartV1 } from "./chart.v1";
import { MeasuredValueV1 } from "./measured-value.v1";

/**
 *
 */
export class MeasurementV1 {
    public id?: string;
    public device?: string;
    public deviceId?: string;
    public serialNumber?: string;
    public timestamp?: string;
    public comment?: string;

    public values: Array<MeasuredValueV1> = [];
    public charts: Array<Chart> = [];

    public static fromEntity(measurement: Measurement): MeasurementV1 {
        const v1: MeasurementV1 = new MeasurementV1();
        v1.id = measurement.id;
        v1.device = measurement.device;
        v1.deviceId = measurement.deviceId;
        v1.serialNumber = measurement.serialNumber;
        v1.timestamp = measurement.timestamp;
        v1.comment = measurement.comment;

        v1.values = [];
        for (const value of measurement.values) {
            v1.values.push(MeasuredValueV1.fromEntity(value));
        }
        v1.charts = [];
        for (const chart of measurement.charts) {
            v1.charts.push(ChartV1.fromEntity(chart));
        }

        return v1;
    }

    public static toEntity(v1: MeasurementV1): Measurement {
        const entity: Measurement = new Measurement();
        entity.id = v1.id ?? CryptoHelper.getUUID();
        entity.device = v1.device;
        entity.deviceId = v1.deviceId;
        entity.serialNumber = v1.serialNumber;
        entity.timestamp = v1.timestamp;
        entity.comment = v1.comment;

        entity.values = [];
        for (const value of v1.values) {
            entity.values.push(MeasuredValueV1.toEntity(value));
        }
        entity.charts = [];
        for (const chart of v1.charts) {
            entity.charts.push(ChartV1.toEntity(chart));
        }

        return entity;
    }
}
