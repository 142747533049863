import { Component, ElementRef, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { FileSharer } from "@byteowls/capacitor-filesharer";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import Swiper from "swiper";
import { SwiperContainer } from "swiper/swiper-element";

import { BaseComponent } from "../../../base/components/base/base-component";
import { ComponentCanDeactivate } from "../../../base/guards/navigate-away.guard";
import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { BluetoothService } from "../../../base/services/ble/bluetooth.service";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { MeasurementTableComponent } from "../../components/measurement-table/measurement-table.component";
import { Job } from "../../datamodel/job";
import { JobTemplate } from "../../datamodel/job-template";
import { MeasuredPoint } from "../../datamodel/measured-point";
import { Measurement } from "../../datamodel/measurement";
import { MeasurementPoint } from "../../datamodel/measurement-point";
import { Part } from "../../datamodel/part";
import { Photo } from "../../datamodel/photo";
import { Property } from "../../datamodel/property";
import { DialogService } from "../../services/dialog/dialog.service";
import { ImageService } from "../../services/image/image-service";
import { JobService } from "../../services/job/job.service";
import { JobModePdf } from "../../services/pdf/documents/jobmode.pdf";
import { JobTemplateExporter } from "../job-designer/sharing/job-template-exporter";

/**
 * The main job-mode view, where the actual measurements are being taken.
 */
@Component({
    selector: "app-job-mode-measurement",
    templateUrl: "./job-mode-measurement.component.html",
    styleUrls: ["./job-mode-measurement.component.scss"]
})
export class JobModeMeasurementComponent extends BaseComponent implements ComponentCanDeactivate {
    constructor(
        private readonly route: ActivatedRoute,
        private readonly jobService: JobService,
        public readonly bluetoothService: BluetoothService,
        private readonly snackbar: MatSnackBar,
        private readonly translateService: TranslateService,
        private readonly navigationService: NavigationService,
        private readonly jobModePdf: JobModePdf,
        private readonly imageService: ImageService,
        private readonly dialogService: DialogService,
        private readonly jobTemplateExporter: JobTemplateExporter
    ) {
        super();
    }

    public currentMeasurements: Array<Measurement> = [];
    public currentMeasurementPoint?: MeasurementPoint;
    public currentPart?: Part;
    public currentPhotos: Array<Photo> = [];
    protected readonly jobClass: typeof Job = Job;
    protected job?: Job;
    protected jobDescription?: string;

    protected canSelectPreviousPart: boolean = false;
    protected canSelectNextPart: boolean = false;

    public slideIndex: number = 0;

    private isModified: boolean = false;

    private swiperContainerElementRefField?: ElementRef<SwiperContainer>;
    @ViewChild("measurementTable")
    private measurementTableComponent?: MeasurementTableComponent;

    public get swiperContainerElementRef(): ElementRef<SwiperContainer>|undefined {
        return this.swiperContainerElementRefField;
    }

    @ViewChild("swiperContainer")
    public set swiperContainerElementRef(value: ElementRef<SwiperContainer>) {
        this.swiperContainerElementRefField = value;
        this.swiperInitialized();
    }

    public async willDeactivate(): Promise<void> {
        if (this.isModified) {
            await this.save();
        }
    }

    public async canDeactivate(): Promise<boolean> {
        return !this.isModified;
    }

    protected componentInit(): void {
        this.initialize().then();
    }

    private updateCanSelectParts(): void {
        this.canSelectPreviousPart = this.slideIndex > 0;
        this.canSelectNextPart = this.slideIndex + 1 < (this.swiperContainerElementRef?.nativeElement?.swiper?.slides?.length ?? 0);
    }

    private swiperInitialized(): void {
        const swiperInstance: Swiper|undefined = this.swiperContainerElementRef?.nativeElement.swiper;
        if (!swiperInstance) {
            return;
        }

        swiperInstance.on("slideChange", () => {
            this.refreshPart();
        });

        swiperInstance.params.autoHeight = false;
        if (swiperInstance.params.cardsEffect) {
            swiperInstance.params.cardsEffect.rotate = false;
        }
        swiperInstance.update();

        this.updateCanSelectParts();
    }

    private async initialize(): Promise<void> {
        const jobId: string|null = this.route.snapshot.paramMap.get("id");
        if (jobId == null) {
            return;
        }

        this.job = await this.jobService.load(parseInt(jobId, 10));
        if (this.job?.parts && this.job.parts.length > 0) {
            this.currentPart = this.job.parts[0];
            if (this.currentPart.image?.measurementPoints && this.currentPart.image.measurementPoints.length > 0) {
                this.currentMeasurementPoint = this.currentPart.image?.measurementPoints[0];
            }
            this.refreshPart();
            this.refreshMeasurements();
        }
        this.jobDescription = this.job?.properties.find((property: Property) => property.nameKey == JobTemplate.jobTemplatePropertyName)?.value;

        this.subscribe(this.bluetoothService.onData, this.bluetoothDataReceived);
        this.subscribe(this.bluetoothService.onDeleteMeasurement, this.onDeleteMeasurement);
    }

    protected componentDestroy(): void {
        // empty
    }

    private bluetoothDataReceived(newMeasurement: Measurement): void {
        if (this.currentPart?.id && this.currentMeasurementPoint?.id) {
            const existingMeasuredPoint: MeasuredPoint|undefined = this.job?.measuredPoints
                .find((point: MeasuredPoint) => point.measurementPointId == this.currentMeasurementPoint?.id);

            if (existingMeasuredPoint) {
                existingMeasuredPoint.measurements.push(newMeasurement);
            } else {
                this.job?.measuredPoints.push(new MeasuredPoint(this.currentPart.id, this.currentMeasurementPoint.id, [newMeasurement]));
            }

            this.save().then();

            this.refreshMeasurements();

            if (this.measurementTableComponent) {
                this.measurementTableComponent.measurements = this.currentMeasurements;
                this.measurementTableComponent.refresh();
            }
        }
    }

    private async onDeleteMeasurement(measurement: Measurement): Promise<void> {
        if (this.job) {
            for (const mp of this.job.measuredPoints) {
                mp.measurements = mp.measurements.filter((m: Measurement) => m.id != measurement.id);
            }
            await this.save();
            this.refreshPart();
        }
    }

    public async onNewPhoto(file: File): Promise<void> {
        if (this.job && this.currentPart?.id) {
            const arrayBuffer: ArrayBuffer = await file.arrayBuffer();
            const binaryId: number = await this.imageService.save(new Blob([arrayBuffer], { type: file.type }));
            const photo: Photo = {
                partId: this.currentPart.id,
                binaryId: binaryId
            };
            this.job.photos.push(photo);
            await this.save();
            this.refreshPhotos();
        }
    }

    public async onRemovePhoto(photo: Photo): Promise<void> {
        if (this.job && photo.binaryId) {
            this.job.photos = this.job?.photos.filter((value: Photo) => value.binaryId != photo.binaryId);
            await this.imageService.delete(photo.binaryId);
            await this.save();
        }
        this.refreshPhotos();
    }

    public nextPart(): void {
        this.swiperContainerElementRef?.nativeElement?.swiper.slideNext();
        this.refreshPart();
    }

    public previousPart(): void {
        this.swiperContainerElementRef?.nativeElement?.swiper.slidePrev();
        this.refreshPart();
    }

    public async save(autosave: boolean = true): Promise<void> {
        if (this.job) {
            this.job.dateISO = DateTime.now().toISO()!;
            await this.jobService.save(this.job);
        }

        if (!autosave) {
            this.snackbar.open(this.translateService.instant("JobMode.saved"), undefined, {
                duration: this.snackbarDuration,
                verticalPosition: this.snackbarVerticalPosition
            });
        }
    }

    public markerChanged(measurementPoint: MeasurementPoint): void {
        this.currentMeasurementPoint = measurementPoint;
        this.refreshMeasurements();
    }

    public async downloadPdf(): Promise<void> {
        if (this.job) {
            await this.jobModePdf.generatePDF(this.job, async (base64Data: string) => {
                try {
                    await FileSharer.share({
                        filename: `${(new Date()).toISOString().split("T")[0]}-${CryptoHelper.getUUID()}.pdf`,
                        contentType: "application/pdf",
                        base64Data: base64Data
                    });
                } catch (error) {
                    console.error(error);
                }
            });
        }
    }

    public async delete(): Promise<void> {
        if (this.job?.id) {
            const result: boolean = await this.dialogService.openDeleteDialog("DeleteDialog.title", "DeleteDialog.description", "", "");

            if (result && this.job?.id) {
                await this.jobService?.delete(this.job.id);
                await this.navigationService.navigateBack();
                this.snackbar.open(this.translateService.instant("JobMode.deleted"), undefined, {
                    duration: this.snackbarDuration,
                    verticalPosition: this.snackbarVerticalPosition
                });
            }

        }
    }

    public async updateProperties(properties: Array<Property>): Promise<void> {
        if (this.job) {
            this.job.properties = [];
            for (const property of properties) {
                if (!property.deleted) {
                    this.job.properties.push(property);
                }
            }

            await this.save();
        }
    }

    public refreshPhotos(): void {
        const photos: Array<Photo> = [];
        if (this.job && this.currentPart) {
            for (const photo of this.job.photos) {
                if (photo.partId == this.currentPart.id) {
                    photos.push(photo);
                }
            }
        }
        this.currentPhotos = photos;
    }

    private refreshMeasurements(): void {
        const measurements: Array<Measurement> = [];
        if (!this.currentPart || !this.currentMeasurementPoint) {
            return;
        }

        const measurementsForMeasuredPoint: Array<Measurement>|undefined = this.job?.measuredPoints
            .filter((measuredPoint: MeasuredPoint) =>
                measuredPoint.measurementPointId == this.currentMeasurementPoint?.id)
            .map((measuredPoint: MeasuredPoint) => measuredPoint.measurements)
            .flat();

        if (measurementsForMeasuredPoint) {
            measurements.push(...measurementsForMeasuredPoint);
        }

        this.currentMeasurements = measurements;
    }

    private refreshPart(): void {
        this.slideIndex = this.swiperContainerElementRef?.nativeElement.swiper.activeIndex ?? 0;
        if (this.job && this.swiperContainerElementRef) {
            this.currentPart = this.job.parts[this.slideIndex];
            this.refreshMeasurementPoint();
            this.refreshMeasurements();
        }
        this.refreshPhotos();
        this.updateCanSelectParts();
    }

    private refreshMeasurementPoint(): void {
        let currentMeasurementPointIsOnCurrentPart: boolean = false;
        if (this.currentPart?.image) {
            for (const mp of this.currentPart?.image?.measurementPoints) {
                if (this.currentMeasurementPoint?.id == mp.id) {
                    currentMeasurementPointIsOnCurrentPart = true;
                    break;
                }
            }
        }
        if (!currentMeasurementPointIsOnCurrentPart
            && this.currentPart?.image?.measurementPoints
            && this.currentPart?.image?.measurementPoints.length > 0) {
            this.currentMeasurementPoint = this.currentPart.image.measurementPoints[0];
        }
    }

    public getPartCaption(part: Part): string {
        return Property.findByNameKey(Part.partPropertyDescription, part.properties)?.value ?? "XX";
    }


    public async exportJob(): Promise<void> {
        if (this.job) {
            await this.jobTemplateExporter.exportJob(this.job);
        }
    }
}
