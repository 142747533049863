<app-menubar [headerUntranslated]="job ? (jobClass.getJobDisplayName(job) ?? ('Job.unnamed' | translate)) : ''" [hideTitleOniOS]="true" [showBackButton]="true">
    <button (click)="exportJob()" class="iconButton" color="primary" mat-stroked-button>
        <fa-icon [icon]="appIcons.downloadFile"></fa-icon>
        <span>{{'JobDesigner.export' | translate}}</span>
    </button>
    <button (click)="downloadPdf()" class="iconButton" color="primary" mat-stroked-button>
        <fa-icon [icon]="appIcons.exportPdfIcon"></fa-icon>
        <span>{{'PdfExport.export' | translate}}</span>
    </button>
</app-menubar>

<ion-content [fullscreen]="true">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title size="large">{{job ? (jobClass.getJobDisplayName(job) ?? ('Job.unnamed' | translate)) : ''}}</ion-title>
        </ion-toolbar>
    </ion-header>

    <div *ngIf="job" class="content">
        <app-property-list
            (propertiesUpdatedEvent)="updateProperties($event)"
            [allowNewProperties]="true"
            [properties]="job.properties">
        </app-property-list>

        <div *ngIf="job.parts" class="partsContainer">
            <button (click)="previousPart()" [disabled]="!canSelectPreviousPart" class="navigationButton" mat-fab>
                <fa-icon [icon]="appIcons.previousMarkerIcon"></fa-icon>
            </button>

            <swiper-container #swiperContainer pagination="true" effect="coverflow" grab-cursor="true" centered-slides="true"
                              slides-per-view="auto" coverflow-effect-rotate="50" coverflow-effect-stretch="0" coverflow-effect-depth="100"
                              coverflow-effect-modifier="1" coverflow-effect-slide-shadows="true">
                <swiper-slide *ngFor="let part of job.parts; let index = index" class="slide">
                    <app-part-editor (markerSelectedEvent)="markerChanged($event)" [caption]="getPartCaption(part)" [disabled]="index != slideIndex" [part]="part" mode="markerSelection"></app-part-editor>
                </swiper-slide>
            </swiper-container>

            <button (click)="nextPart()" [disabled]="!canSelectNextPart" class="navigationButton" mat-fab>
                <fa-icon [icon]="appIcons.nextMarkerIcon"></fa-icon>
            </button>
        </div>

        <div class="photos">
            <app-photo-list (newPhotoEvent)="onNewPhoto($event)" (removePhotoEvent)="onRemovePhoto($event)" [photos]="currentPhotos"></app-photo-list>
        </div>

        <div class="measurementsList">
            <app-measurement-table #measurementTable
                                   (deleteMeasurementEvent)="this.bluetoothService.deleteMeasurement($event)"
                                   (dirtyStateEvent)="this.save()"
                                   [measurements]="currentMeasurements"
            >
            </app-measurement-table>
        </div>

        <button
            (click)="delete()"
            *ngIf="job.id"
            class="bigButton"
            color="warn"
            mat-flat-button>
            {{'Generic.delete' | translate}}
        </button>
    </div>
</ion-content>
