import { Component, ViewChild } from "@angular/core";
import { MatTable } from "@angular/material/table";

import { environment } from "../../../../environments/environment";
import { AppIcons } from "../../../app-icons";
import { BaseComponent } from "../../../base/components/base/base-component";
import { SubscriptionDto } from "../../../generated/api";
import { SubscriptionService } from "../../services/subscription/subscription.service";
import { LicenseRow } from "./license-row";

/**
 * Page to show the current licenses.
 */
@Component({
    selector: "app-license",
    templateUrl: "./license.component.html",
    styleUrls: ["./license.component.scss"]
})
export class LicenseComponent extends BaseComponent {
    constructor(
        private readonly subscriptionService: SubscriptionService
    ) {
        super();
    }

    @ViewChild("infoTable")
    private infoTable?: MatTable<LicenseRow>;
    public displayedColumns: Array<string> = ["loading", "serialNumber", "status", "termDates", "interval", "planName", "expireDate", "billingDate", "scheduledCancellationDate"];

    private subscriptions: Array<SubscriptionDto> = [];
    protected subscriptionRows: Array<LicenseRow> = [];

    protected loading: boolean = false;

    public override readonly appIcons: typeof AppIcons = AppIcons;

    private initialize(): Promise<void> {
        return this.refresh();
    }

    protected override componentInit(): void {
        this.initialize().then();
    }

    protected override componentDestroy(): void {
        // Do nothing
    }

    protected async refresh(): Promise<void> {
        try {
            this.loading = true;

            this.subscriptions = await this.subscriptionService.getAllSubscriptions();
            this.subscriptionRows = [];

            for (const subscription of this.subscriptions) {
                if (subscription.serialNumber && subscription.planCode) {
                    const row: LicenseRow = subscription as LicenseRow;
                    this.subscriptionRows.push(row);
                }
            }

            for (let index: number = 0; index < this.subscriptionRows.length; index++) {
                let row: LicenseRow = this.subscriptionRows[index];
                row.loading = true;
                try {
                    row = await this.subscriptionService.getFromServer(row.serialNumber!, row.planCode!) as LicenseRow;
                    this.subscriptionRows[index] = row;
                } finally {
                    row.loading = false;
                }
                this.infoTable?.renderRows();
            }
        } finally {
            this.infoTable?.renderRows();
            this.loading = false;
        }
    }

    protected openPortal(): void {
        window.open(environment.licensePortalUrl, "_blank");
    }
}

