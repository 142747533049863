<table *ngIf="highlightLastMeasurement && this.measurements.length > 0" class="lastMeasuredTable">
    <tr *ngFor="let measuredValue of getLastMeasurement().values">
        <td>
            {{"" + measuredValue.name | translate}}
        </td>
        <td>
            {{uiHelper.formatValue(measuredValue)|translate}}
        </td>
        <td>
            {{uiHelper.formatValueUnit(measuredValue)|translate}}
        </td>
    </tr>
</table>

<div class="measurementsList">
    <table #measurementMatTable *ngIf="measurements.length > 0" [dataSource]="measurements" mat-table>
        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
            <th *matHeaderCellDef [id]="column" mat-header-cell>
                <span *ngIf="column != columnNameComments && column != columnNameCharts" class="cellHeader">{{column|translate}}<br />&nbsp;{{measurementTableComponent.getUnitForColumn(column, measurements[0], true, true)}}</span>
                <span *ngIf="column == columnNameCharts" class="cellHeaderCharts">{{column | translate}}<br />&nbsp;</span>
                <span *ngIf="column == columnNameComments" class="cellHeaderComment">{{column | translate}}<br />&nbsp;</span>
            </th>

            <td *matCellDef="let measurement" mat-cell>
                <fa-icon (click)="deleteMeasurement(measurement)" *ngIf="column == columnNameActions" [icon]="appIcons.trash" class="cellContent"></fa-icon>
                <span (click)="editComment(measurement)" *ngIf="column != columnNameActions && column != columnNameComments && column != columnNameCharts" class="cellContent">
                    {{measurementTableComponent.getValueForColumn(column, measurement) | translate}}
                </span>
                <span (click)="openChart(measurement)" *ngIf="column == columnNameCharts && measurement.charts.length > 0" class="cellContentCharts">
                    <fa-icon [icon]="appIcons.chart"></fa-icon>
                </span>
                <span (click)="editComment(measurement)" *ngIf="column == columnNameComments" class="cellContentComment">
                    {{measurementTableComponent.getValueForColumn(column, measurement) | translate}}
                </span>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" class="tableHeader" mat-header-row></tr>
        <tr *matRowDef="let p; columns: displayedColumns;" mat-row></tr>
    </table>

    <table #statisticsTable *ngIf="showStatistics && measurements.length > 1" [dataSource]="statistics" mat-table>
        <ng-container *ngFor="let column of statisticsColumns" [matColumnDef]="column">
            <th *matHeaderCellDef [id]="column" mat-header-cell class="cellContentSummary">{{column | translate}}<br />&nbsp;{{measurementTableComponent.getUnitForColumn(column, measurements[0], true, true)}}</th>
            <td *matCellDef="let statistic" mat-cell class="cellContentSummary">{{getStatisticsValue(statistic, column) | translate}}</td>
        </ng-container>

        <tr *matHeaderRowDef="statisticsColumns" class="tableHeader" mat-header-row></tr>
        <tr *matRowDef="let p; columns: statisticsColumns;" mat-row></tr>
    </table>
</div>

<div *ngIf="measurements.length == 0">
    <mat-card>
        <mat-card-content>{{'QuickMode.noMeasurements' | translate}}</mat-card-content>
    </mat-card>
</div>

