export enum DeviceNames {
    unknown = "unknown",
    iq = "IQ",
    glossMeter = "Glossmeter",
    detailometer = "Detailometer",
    duo = "Novo-Shade Duo",
    novoGloss = "Novo-Gloss",
    flex = "IQ Flex",

    thicknessGauge = "Coating Thickness Gauge"
}
