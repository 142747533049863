import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import DomToImage from "dom-to-image";

import { BaseComponent } from "../../../base/components/base/base-component";
import { MarkerAnnotationComponent } from "../../../base/components/marker-annotation/marker-annotation.component";
import { ComponentCanDeactivate } from "../../../base/guards/navigate-away.guard";
import { AsyncHelper } from "../../../base/helper/async-helper";
import { readFileAsDataUrlAsync } from "../../../base/helper/file-reader-helper";
import { Image } from "../../datamodel/image";
import { MeasurementPoint } from "../../datamodel/measurement-point";
import { Part } from "../../datamodel/part";
import { Property } from "../../datamodel/property";
import { ImageService } from "../../services/image/image-service";
import { PartService } from "../../services/part/part-service";
import { PartEditorModes } from "./part-editor-modes";

/**
 * PartEditorComponent offers functions to create and modify a part, i.e. Set measurement points and properties.
 */
@Component({
    selector: "app-part-editor",
    templateUrl: "./part-editor.component.html",
    styleUrls: ["./part-editor.component.scss"]
})
export class PartEditorComponent extends BaseComponent implements ComponentCanDeactivate {
    constructor(
        private readonly partService: PartService,
        private readonly imageService: ImageService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    @ViewChild("imageContainer")
    private imageContainerRef?: ElementRef<HTMLDivElement>;

    @Input()
    public mode: PartEditorModes = "edit";
    @Output("partUpdatedEvent")
    public partUpdatedEvent: EventEmitter<Part> = new EventEmitter<Part>();
    @Output("markerSelectedEvent")
    public markerSelectedEvent: EventEmitter<MeasurementPoint> = new EventEmitter<MeasurementPoint>();
    public markers: Array<MeasurementPoint> = [];
    public selectedMarker?: MeasurementPoint;
    @ViewChild("markerAnnotationComponent")
    public markerAnnotationComponent?: MarkerAnnotationComponent;
    protected imageSource?: string;
    private partBacking: Part = new Part();
    @ViewChild("imageSelect")
    private imageSelectRef?: ElementRef<HTMLInputElement>;
    private image?: File;

    @Input()
    public disabled: boolean = false;

    @Input()
    public get part(): Part {
        return this.partBacking;
    }

    public set part(value: Part) {
        this.partBacking = value;
        this.initializeAndLoadImage().then();
    }

    protected get isReadonly(): boolean {
        return this.mode == "readonly";
    }

    protected get canSelectNextMarker(): boolean {
        return this.selectedMarker?.order != undefined
            && this.selectedMarker.order < this.markers.length;
    }

    protected get canSelectPreviousMarker(): boolean {
        return this.selectedMarker?.order != undefined
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            && this.selectedMarker.order - 2 >= 0;
    }

    protected componentInit(): void {
        // Do nothing for now
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public async willDeactivate(): Promise<void> {
        await this.save();
    }

    public async canDeactivate(): Promise<boolean> {
        return true;
    }

    public loadMarkers(): void {
        this.markers = [];

        if (this.part?.image?.measurementPoints) {
            this.markers = this.part.image.measurementPoints;
        }

        this.markers
            .filter((predicate: MeasurementPoint) => predicate.order != undefined)
            .sort((predicate: MeasurementPoint) => predicate.order!);

        if (this.mode == "markerSelection") {
            if (this.markers.length > 0) {
                this.selectedMarker = this.markers[0];
            }
        }
    }

    public async initializeAndLoadImage(): Promise<void> {
        if (this.part.image?.binaryId) {
            const blob: Blob|undefined = await this.imageService.load(this.part.image.binaryId);
            if (blob) {
                this.image = new File([blob], "image");

            }
        }
        await this.showImage();
        this.loadMarkers();
    }

    public async selectImage(): Promise<void> {
        if (this.imageSelectRef?.nativeElement) {
            this.imageSelectRef.nativeElement.onchange = async (event: Event): Promise<void> => {
                const files: FileList|null = (event.target as HTMLInputElement)?.files;

                if (files && files.length > 0) {
                    this.image = files[0];
                    await this.showImage();
                    await this.save();
                }
            };
            this.imageSelectRef.nativeElement.click();
        }
    }

    public async showImage(): Promise<void> {
        if (this.image) {
            this.imageSource = await readFileAsDataUrlAsync(this.image);
        }
    }

    public addMarker(): void {
        this.markerAnnotationComponent?.addMarker();
    }

    public cancelAddMarker(): void {
        this.markerAnnotationComponent?.cancelAddMarker();
    }

    public async save(): Promise<void> {
        if (this.mode == "readonly") {
            return;
        }

        if (this.image) {
            const blob: Blob = await this.toBlob(this.image);
            const imageId: number = await this.imageService.save(blob);
            if (!this.part.image) {
                this.part.image = new Image();
            }
            this.part.image.binaryId = imageId;
            if (this.markerAnnotationComponent) {
                this.markerAnnotationComponent.removeStyles = true;
                this.changeDetectorRef.detectChanges();
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                await AsyncHelper.sleep(10);

                this.part.image.measurementPoints = this.markerAnnotationComponent.markers;
            }
            const node: HTMLDivElement|undefined = this.imageContainerRef?.nativeElement;
            if (node) {
                this.part.image.imageWithMeasurementPointsAsDataURL = await DomToImage.toPng(node, {});
            }
            if (this.markerAnnotationComponent) {
                this.markerAnnotationComponent.removeStyles = false;
            }
        }

        await this.partService.save(this.part);
    }

    public getPartProperties(): Array<Property> {
        return this.part.properties;
    }

    public setPartProperties(properties: Array<Property>): void {
        if (this.part?.properties) {
            this.part.properties = [];
            for (const property of properties) {
                if (!property.deleted) {
                    this.part.properties.push(property);
                }
            }
            this.save().then();
        }
    }

    public selectPreviousMarker(): void {
        if (!this.canSelectPreviousMarker || this.selectedMarker?.order == undefined) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.selectedMarker = this.markers[this.selectedMarker?.order - 2];
        this.markerSelectedEvent.emit(this.selectedMarker);
    }

    public selectNextMarker(): void {
        if (!this.canSelectNextMarker || this.selectedMarker?.order == undefined) {
            return;
        }

        this.selectedMarker = this.markers[this.selectedMarker?.order];
        this.markerSelectedEvent.emit(this.selectedMarker);
    }

    private async toBlob(file: File): Promise<Blob> {
        const arrayBuffer: ArrayBuffer = await file.arrayBuffer();
        return new Blob([arrayBuffer]);
    }

    public async deleteAllMarkers(): Promise<void> {
        if (this.part.image) {
            this.part.image.measurementPoints = [];
            this.markers = [];
            if (this.markerAnnotationComponent) {
                this.markerAnnotationComponent.markers = [];
            }
            await this.save();
        }
    }

    public selectedMarkerChanged(): void {
        this.markerSelectedEvent.emit(this.selectedMarker);
    }
}
