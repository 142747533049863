import { CdkDrag, CdkDragHandle, CdkDropList } from "@angular/cdk/drag-drop";
import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { RouterLink } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

import { BaseComponentsModule } from "../../base/components/base-components.module";
import { BluetoothService } from "../../base/services/ble/bluetooth.service";
import { BusinessComponentsModule } from "../components/business-components.module";
import { ImageService } from "../services/image/image-service";
import { JobTemplateService } from "../services/job-template/job-template.service";
import { PartService } from "../services/part/part-service";
import { QuickModePdf } from "../services/pdf/documents/quickmode.pdf";
import { PdfGenerator } from "../services/pdf/pdf-generator";
import { HomeComponent } from "./home/home.component";
import { JobDesignerComponent } from "./job-designer/job-designer.component";
import { JobListComponent } from "./job-list/job-list.component";
import { JobModeMeasurementComponent } from "./job-mode-measurement/job-mode-measurement.component";
import { JobTemplateListComponent } from "./job-template-list/job-template-list.component";
import { LicenseComponent } from "./license/license.component";
import { PartDesignerComponent } from "./part-designer/part-designer.component";
import { PartListComponent } from "./part-list/part-list.component";
import { QuickModeComponent } from "./quick-mode/quick-mode.component";
import { SettingsComponent } from "./settings/settings.component";
import { TabsPage } from "./tabs/tabs.page";

/**
 * Module defining all pages of the detailing app.
 */
@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        HomeComponent,
        JobDesignerComponent,
        PartDesignerComponent,
        PartListComponent,
        JobTemplateListComponent,
        SettingsComponent,
        QuickModeComponent,
        JobListComponent,
        JobModeMeasurementComponent,
        LicenseComponent,
        TabsPage
    ],
    imports: [
        CommonModule,
        BrowserModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        FontAwesomeModule,
        TranslateModule,
        MatListModule,
        RouterLink,
        BaseComponentsModule,
        MatCardModule,
        NgOptimizedImage,
        MatTableModule,
        CdkTableModule,
        BusinessComponentsModule,
        MatSelectModule,
        MatExpansionModule,
        CdkDropList,
        CdkDrag,
        CdkDragHandle,
        MatMenuModule,
        MatInputModule,
        FormsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        IonicModule
    ],
    exports: [],
    providers: [
        PartService,
        ImageService,
        BluetoothService,
        PdfGenerator,
        QuickModePdf,
        JobTemplateService
    ]
})
export class PagesModule {
}
