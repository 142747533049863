import { Injectable } from "@angular/core";
import { DateTime } from "luxon";

import { CryptoHelper } from "../../../../base/helper/crypto-helper";
import { Image } from "../../../datamodel/image";
import { Job } from "../../../datamodel/job";
import { JobTemplate } from "../../../datamodel/job-template";
import { Part } from "../../../datamodel/part";
import { Photo } from "../../../datamodel/photo";
import { ImageService } from "../../../services/image/image-service";
import { PartService } from "../../../services/part/part-service";
import { SchemaMetaData } from "./schema-meta-data";
import { JobV1 } from "./v1/job.v1";
import { JobFileV1 } from "./v1/job-file.v1";
import { JobTemplateV1 } from "./v1/job-template.v1";
import { JobTemplateFileV1 } from "./v1/job-template-file.v1";
import { MeasuredPointV1 } from "./v1/measured-point.v1";
import { MeasurementPointV1 } from "./v1/measurement-point.v1";
import { PartV1 } from "./v1/part.v1";
import { PhotoV1 } from "./v1/photo.v1";
import { PropertyV1 } from "./v1/property.v1";

/**
 *
 */
@Injectable({
    providedIn: "root"
})
export class JobTemplateImporter {

    constructor(
        private partService: PartService,
        private imageService: ImageService
    ) {}

    public async import(file: File): Promise<JobTemplate|Job|undefined> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const json: any = JSON.parse(new TextDecoder().decode(await file.arrayBuffer()));
        if (json.metaData) {
            const metaData: SchemaMetaData = json.metaData as SchemaMetaData;
            if (metaData.type == "jobTemplate") {
                if (metaData.version == "v1") {
                    return await this.importJobTemplateV1(json as JobTemplateFileV1);
                }
                throw new Error(`Unknown version: ${JSON.stringify(metaData.version)}`);
            } else if (metaData.type == "job") {
                if (metaData.version == "v1") {
                    return await this.importJobV1(json as JobFileV1);
                }
                throw new Error(`Unknown version: ${JSON.stringify(metaData.version)}`);
            } else {
                throw new Error(`Unknown type: ${JSON.stringify(metaData.type)}`);
            }
        } else {
            throw new Error(`Uploaded file does not contain the metaData property. Aborting import. Got: ${JSON.stringify(json)}`);
        }
    }

    private async importJobTemplateV1(jobTemplateFileV1: JobTemplateFileV1): Promise<JobTemplate|undefined> {
        if (jobTemplateFileV1.jobTemplate) {
            return this.jobTemplateV1ToJobTemplate(jobTemplateFileV1.jobTemplate, true);
        } else {
            throw new Error("No job template in json.");
        }
    }

    private async jobTemplateV1ToJobTemplate(jobTemplateV1: JobTemplateV1, importParts: boolean): Promise<JobTemplate> {
        const jobTemplate: JobTemplate = new JobTemplate();
        jobTemplate.correlationId = CryptoHelper.getUUID();
        jobTemplate.version = 1;
        jobTemplate.dateISO = DateTime.now().toISO()!;

        if (jobTemplateV1.parts) {
            jobTemplate.parts = await Promise.all(jobTemplateV1.parts.map((partV1: PartV1) => this.fromPartV1(partV1, importParts)));
        }

        if (jobTemplateV1.properties) {
            jobTemplate.properties = jobTemplateV1.properties.map(PropertyV1.toEntity);
        }

        return jobTemplate;
    }

    private async importJobV1(jobFileV1: JobFileV1): Promise<Job|undefined> {
        if (jobFileV1.job) {
            const jobV1: JobV1 = jobFileV1.job;
            const jobTemplate: JobTemplate = await this.jobTemplateV1ToJobTemplate(jobV1, false);
            const job: Job = Job.from(jobTemplate);

            if (jobV1.measuredPoints) {
                job.measuredPoints = await Promise.all(jobV1.measuredPoints.map(MeasuredPointV1.toEntity));
            }
            for (const photoV1 of jobV1.photos ?? []) {
                job.photos.push(await this.fromPhotoV1(photoV1));
            }


            return job;
        } else {
            throw new Error("No job in json.");
        }
    }

    private async fromPartV1(partV1: PartV1, importPats: boolean): Promise<Part> {
        const part: Part = new Part();
        part.image = new Image();
        part.id = partV1.id;
        part.correlationId = partV1.correlationId;
        part.version = partV1.version;
        if (partV1.image) {
            if (partV1.image.binaryAsDataURL) {
                const binary: Blob = await (await fetch(partV1.image?.binaryAsDataURL)).blob();
                part.image.binaryId = await this.imageService.save(binary);
            }
            if (partV1.image.measurementPoints) {
                part.image.measurementPoints = partV1.image.measurementPoints.map(MeasurementPointV1.toEntity);
            }
            part.image.imageWithMeasurementPointsAsDataURL = partV1.image.imageWithMeasurementPointsAsDataURL;
        }
        if (partV1.properties) {
            part.properties = partV1.properties.map(PropertyV1.toEntity);
        }
        if (importPats) {
            await this.partService.save(part);
        }
        return part;
    }

    private async fromPhotoV1(v1: PhotoV1): Promise<Photo> {
        const partialEntity: Partial<Photo> = PhotoV1.toPartialEntity(v1);
        const entity: Photo = new Photo();
        Object.assign(entity, partialEntity);

        if (v1.binaryAsDataURL) {
            const binary: Blob = await (await fetch(v1.binaryAsDataURL)).blob();
            entity.binaryId = await this.imageService.save(binary);
        }

        return entity;
    }
}
